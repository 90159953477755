<template>
  <page-container title="机构管理管理" :breadcrumb="breadcrumb">
    <div class="dept-container">
      <div class="box-container" style="margin-top: 24px; width: 320px; padding-bottom: 24px;">
        <div class="box-container-inner" style="height: calc(100vh - 200px); overflow: auto;">
          <div class="tree-top">
            <a-button v-show="showadd" type="primary" @click="addNewDept">新增</a-button>
            <a-select
                show-search
                :placeholder="'在'+searchRangeName+'中查询'"
                option-filter-prop="children"
                style="float: right;width: 70%"
                :filter-option="filterOption"
                @change="handleChange"
                :dropdownMatchSelectWidth=false
                dropdownClassName="select-drop-down-platform"
            >
              <a-select-option v-for="(item, index) in userDepOptions" :key="index" :value="item.userdepid">{{item.userdepname}}</a-select-option>
            </a-select>
          </div>
          <a-tree
            :selected-keys.sync="selectedKeys"
            :replace-fields="{children:'children', title:'userdepname', key:'userdepid'}"
            :tree-data="userDeptTree"
            :load-data="onLoadData"
            @select="selectDept"
          >
            <span slot="customTreeNode" slot-scope="node">
              <span>{{node.dataRef.userdepname}}</span>
              <a-tooltip>
                <template slot="title">人员列表</template>
                <a-icon type="team" @click.stop="showPeopleList(node)" style="margin-left: 7px;" />
              </a-tooltip>
              <a-tooltip>
                <template slot="title">新增下级机构</template>
                <a-icon type="plus" @click.stop="addChildQuick(node)" style="margin-left: 7px;" />
              </a-tooltip>
              <a-tooltip>
                <template slot="title">查询下级机构</template>
                <a-icon type="search" @click.stop="searchChildQuick(node)" style="margin-left: 7px;" />
              </a-tooltip>
              <a-tooltip>
                <template slot="title">删除机构</template>
                <a-icon type="delete" @click.stop="deleteQuick(node)" style="margin-left: 7px;" />
              </a-tooltip>
            </span>
          </a-tree>
        </div>
      </div>
      <div class="box-container" style="margin-top: 24px; flex: 1; padding-bottom: 24px; padding-left: 0;">
        <div class="box-container-inner" style="height: calc(100vh - 200px); overflow-y: auto;">
          <a-form-model ref="userDeptForm" layout="inline" :model="formDatas" :rules="formRules" class="form-in-twocolumns" :label-col="{span:10}" :wrapper-col="{span:14}">
            <a-form-model-item :wrapper-col="{ span: 22, offset: 0 }" style="width: 100%; margin-bottom: 20px; overflow: hidden;">
              <a-button v-if="formType=='edit'" size="large" @click="addChild" style="margin-left: 10px;float: right;"><a-icon type="plus"></a-icon>新增下级机构</a-button>
              <a-button v-if="formType=='edit'" size="large" type="danger" @click="deleteConfirm" style="margin-left: 10px; float: right;">删除</a-button>
              <a-button style="float: right;" v-if="selectedItem||formType=='addNew'" size="large" type="primary" @click="save">保存</a-button>
            </a-form-model-item>
            <a-form-model-item label="父机构" prop="parentid">
              <a-input :value="parentname" disabled />
            </a-form-model-item>
            <a-form-model-item label="机构名称" prop="userdepname">
              <a-input v-model.trim="formDatas.userdepname" />
            </a-form-model-item>
            <a-form-model-item label="机构类型" prop="deptype">
              <a-select v-model="formDatas.deptype" :disabled="formType=='add'">
                <a-select-option v-for="item in deptTypes" :key="item.dickey" :value="parseInt(item.dickey,10)">{{item.dicvalue}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="机构级别" prop="levelid">
              <a-select v-model="formDatas.levelid">
                <a-select-option v-for="item in levelidListOptions" :key="item.dickey" :value="item.dickey">{{item.dicvalue}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item v-if="formDatas.deptype == '1'" label="物业品牌" prop="liftpropertyid">
              <a-select v-model="formDatas.liftpropertyid">
                <a-select-option v-for="(item, index) in userDeptOptions" :key="index" :value="item.liftpropertyid">{{item.value}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="机构简称" prop="abbreviation">
              <a-input v-model.trim="formDatas.abbreviation" />
            </a-form-model-item>
            <a-form-model-item label="序号" prop="sequenceno">
              <a-input v-model.trim="formDatas.sequenceno" />
            </a-form-model-item>
            <a-form-model-item label="地区" prop="areacode">
              <a-cascader :field-names="{ label: 'areaname', value: 'areacode', children: 'children' }" :options="areaOptions" v-model="formDatas.areaList" :load-data="onLoadAreaChildren" placeholder="请选择区域" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="详细地址" prop="address">
              <a-input v-model.trim="formDatas.address" />
            </a-form-model-item>
            <a-form-model-item label="单位电话" prop="telphone" :max-length="12">
              <a-input v-model.trim="formDatas.telphone" />
            </a-form-model-item>
            <a-form-model-item label="邮编" prop="mailcode">
              <a-input v-model.trim="formDatas.mailcode" />
            </a-form-model-item>
            <!-- 维保和检测 -->
            <template v-if="formDatas.deptype == '3' || formDatas.deptype == '5'">
              <a-form-model-item label="救援人员" prop="rescuepreson">
                <a-input v-model.trim="formDatas.rescuepreson" />
              </a-form-model-item>
              <a-form-model-item label="救援人员电话" prop="rescuephone">
                <a-input v-model.trim="formDatas.rescuephone" :max-length="11" />
              </a-form-model-item>
<!--              <a-form-model-item :label="formDatas.deptype == '5'?'检测负责人1':'维保负责人1'" prop="maintenanceperson1">-->
<!--                <a-input v-model.trim="formDatas.maintenanceperson1" />-->
<!--              </a-form-model-item>-->
<!--              <a-form-model-item :label="formDatas.deptype == '5'?'检测负责人1电话':'维保负责人1电话'" prop="maintenancephone1">-->
<!--                <a-input v-model.trim="formDatas.maintenancephone1" :max-length="11" />-->
<!--              </a-form-model-item>-->
<!--              <a-form-model-item :label="formDatas.deptype == '5'?'检测负责人2':'维保负责人2'" prop="maintenanceperson2">-->
<!--                <a-input v-model.trim="formDatas.maintenanceperson2" />-->
<!--              </a-form-model-item>-->
<!--              <a-form-model-item :label="formDatas.deptype == '5'?'检测负责人2电话':'维保负责人2电话'" prop="maintenancephone2">-->
<!--                <a-input v-model.trim="formDatas.maintenancephone2" :max-length="11" />-->
<!--              </a-form-model-item>-->
            </template>
            <!-- 救援单位 -->
            <template v-if="formDatas.deptype == '6'">
              <a-form-model-item label="驻站人1" prop="stationperson1">
                <a-input v-model.trim="formDatas.stationperson1"/>
              </a-form-model-item>
              <a-form-model-item label="驻站人1电话" prop="stationphone1">
                <a-input v-model.trim="formDatas.stationphone1" :max-length="11" />
              </a-form-model-item>
              <a-form-model-item label="驻站人2" prop="stationperson2">
                <a-input v-model.trim="formDatas.stationperson2"/>
              </a-form-model-item>
              <a-form-model-item label="驻站人2电话" prop="stationphone2">
                <a-input v-model.trim="formDatas.stationphone2" :max-length="11" />
              </a-form-model-item>
              <a-form-model-item label="二级救援负责人" prop="pristationperson">
                <a-input v-model.trim="formDatas.pristationperson" />
              </a-form-model-item>
              <a-form-model-item label="二级救援负责人电话" prop="pristationperphone">
                <a-input v-model.trim="formDatas.pristationperphone" :max-length="11" />
              </a-form-model-item>
              <a-form-model-item label="二级救援站" prop="pristation">
                <a-input v-model.trim="formDatas.pristation" />
              </a-form-model-item>
              <a-form-model-item label="二级救援站电话" prop="pristationphone">
                <a-input v-model.trim="formDatas.pristationphone" :max-length="11" />
              </a-form-model-item>
            </template>
            <!-- 使用单位 -->
            <template v-if="formDatas.deptype == '1'">
              <a-form-model-item label="安全管理员" prop="safeperson">
                <a-input v-model.trim="formDatas.safeperson" />
              </a-form-model-item>
              <a-form-model-item label="安全管理员电话" prop="safephone">
                <a-input v-model.trim="formDatas.safephone" :max-length="11" />
              </a-form-model-item>
            </template>
            <a-form-model-item label="区域经理" prop="areamanager">
              <a-input v-model.trim="formDatas.areamanager" />
            </a-form-model-item>
            <a-form-model-item label="区域经理电话" prop="areamanagerphone">
              <a-input v-model.trim="formDatas.areamanagerphone" :max-length="11" />
            </a-form-model-item>
            <a-form-model-item label="单位经理" prop="depmanager">
              <a-input v-model.trim="formDatas.depmanager" />
            </a-form-model-item>
            <a-form-model-item label="单位经理电话" prop="depmanagerphone">
              <a-input v-model.trim="formDatas.depmanagerphone" :max-length="11" />
            </a-form-model-item>
            <a-form-model-item label="法人" prop="legalperson">
              <a-input v-model.trim="formDatas.legalperson" />
            </a-form-model-item>
            <a-form-model-item label="法人电话" prop="legalphone">
              <a-input v-model.trim="formDatas.legalphone" :max-length="11" />
            </a-form-model-item>
            <a-form-model-item label="法人身份证" prop="legalid">
              <a-input v-model.trim="formDatas.legalid" />
            </a-form-model-item>
            <a-form-model-item label="坐标" prop="coordinatex">
              <a-input v-model.trim="formDatas.coordinatex" style="width: 30%;" />
              <a-input v-model.trim="formDatas.coordinatey" style="width: 30%;margin-left: 5%;" />
              <a-button type="primary" @click="selectCoordinateVisible=true" style="min-width: 60px;width: 30%;margin-left: 5%;">选择</a-button>
            </a-form-model-item>
            <a-form-model-item label="资质证书代码" prop="certificatenum">
              <a-input v-model.trim="formDatas.certificatenum" />
            </a-form-model-item>
            <a-form-model-item label="资质证书照片" prop="certificatepic">
              <a-upload name="fileList" :headers="uploadHeaders" :data="uploadData" accept=".jpg,.png" :before-upload="beforeUpload" action="/upload" :file-list="certificatepicList" @change="certificatepicUploaded">
                <a-button> <a-icon type="upload" /> 点击上传文件 </a-button>
              </a-upload>
            </a-form-model-item>
            <a-form-model-item label="组织机构代码" prop="depnum">
              <a-input v-model.trim="formDatas.depnum" />
            </a-form-model-item>
            <a-form-model-item label="组织机构照片" prop="deppic">
              <a-upload name="fileList" :headers="uploadHeaders" :data="uploadData" accept=".jpg,.png" :before-upload="beforeUpload" action="/upload" :file-list="deppicList" @change="deppicUploaded">
                <a-button> <a-icon type="upload" /> 点击上传文件 </a-button>
              </a-upload>
            </a-form-model-item>
            <a-form-model-item label="营业执照" prop="businessnum">
              <a-input v-model.trim="formDatas.businessnum" />
            </a-form-model-item>
            <a-form-model-item label="营业执照照片" prop="businesspic">
              <a-upload name="fileList" :headers="uploadHeaders" :data="uploadData" accept=".jpg,.png" :before-upload="beforeUpload" action="/upload" :file-list="businesspicList" @change="businesspicUploaded">
                <a-button> <a-icon type="upload" /> 点击上传文件 </a-button>
              </a-upload>
            </a-form-model-item>
            <a-form-model-item label="安装许可证" prop="licensenum">
              <a-input v-model.trim="formDatas.licensenum" />
            </a-form-model-item>
            <a-form-model-item label="许可证照片" prop="licensepic">
              <a-upload name="fileList" :headers="uploadHeaders" :data="uploadData" accept=".jpg,.png" :before-upload="beforeUpload" action="/upload" :file-list="licensepicList" @change="licensepicUploaded">
                <a-button> <a-icon type="upload" /> 点击上传文件 </a-button>
              </a-upload>
            </a-form-model-item>
            <a-form-model-item label="描述" prop="userdepdesc">
              <a-input v-model.trim="formDatas.userdepdesc" />
            </a-form-model-item>
            <!-- <a-form-model-item :wrapper-col="{ span: 14, offset: 6 }">
              <a-button v-if="selectedItem||formType=='addNew'" size="large" type="primary" @click="save">保存</a-button>
              <a-button v-if="formType=='edit'" size="large" type="danger" @click="deleteConfirm" style="margin-left: 10px;">删除</a-button>
              <a-button v-if="formType=='edit'" size="large" @click="addChild" style="margin-left: 10px;"><a-icon type="plus"></a-icon>新增下级机构</a-button>
            </a-form-model-item> -->
          </a-form-model>
        </div>
      </div>
    </div>
    <select-coordinate :visible.sync="selectCoordinateVisible" @get-coordinate="getCoordinate"></select-coordinate>
    <people-list-modal :visible.sync="peoplelistModalVisible" :userdepid="peopleModalSelectedDeptid"></people-list-modal>
  </page-container>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import {
  getLiftListByCondition,
  getChildTreeListByParentId,
  addUserdep,
  modifyUserdep,
  deleteUserdep,
} from 'A/jcgn.js'
import {getDictByDicType, geteChildListByParentcode, getParentTreeBycode, getUserListByCondition} from 'A/xtpz.js'
import {getCache, getItemFromArrayByKey, getTreeDeepString} from 'U'
import store from '@/store'
import SelectCoordinate from 'C/modals/SelectCoordinate.vue'
import PeopleListModal from './PeopleListModal.vue'

export default {
  name: 'userDeptManagement',
  components: {
    SelectCoordinate,
    PeopleListModal,
  },
  data() {
    return {
      deptTypes: [],
      levelidList: [],
      areaOptions: [],
      userDeptOptions: [],
      breadcrumb: [
        {
          name: '基础功能管理',
          path: ''
        },
        {
          name: '基础信息管理',
          path: ''
        },
        {
          name: '机构管理管理',
          path: ''
        },
      ],
      showadd:false,
      searchRangeName:'组织机构',
      userDepOptions:[],
      userDeptTree: [],
      selectedKeys: [],
      selectedItem: null,
      formType: '',
      formDatas: {
        userdepid: '',
        parentid: '',
        userdepname: '',
        deptype: '',
        levelid: '',
        abbreviation: '',
        sequenceno: 1,
        areaList: [],
        areacode: '',
        address: '',
        telphone: '',
        mailcode: '',
        rescuepreson: '',
        rescuephone: '',
        maintenanceperson1: '',
        maintenancephone1: '',
        maintenanceperson2: '',
        maintenancephone2: '',
        safeperson: '',
        safephone: '',
        areamanager: '',
        areamanagerphone: '',
        depmanager: '',
        depmanagerphone: '',
        legalperson: '',
        legalphone: '',
        legalid: '',
        coordinatex: '',
        coordinatey: '',
        certificatenum: '',
        certificatepic: '',
        depnum: '',
        deppic: '',
        businessnum: '',
        businesspic: '',
        licensenum: '',
        licensepic: '',
        liftpropertyid: '',
        userdepdesc: '',
        createtime:'',
        updatetime:'',
        maintenancedephone:'',
        stationperson1:'',
        stationphone1:'',
        stationperson2:'',
        stationphone2:'',
        pristationperson:'',
        pristationperphone:'',
        pristation:'',
        pristationphone:'',
      },
      formRules: {
        userdepname: [{required: true, message: '请输入机构名称'}],
        deptype: [{required: true, message: '请选择机构类型'}],
        levelid: [{required: true, message: '请选择机构级别'}],
        telphone: [
          {
            validator: (rule, value, callback) => {
              if(!value||/^\d{7,12}$/.test(value)) {
                callback();
              }else {
                callback('请输入正确的电话号码')
              }
            }, trigger: 'blur'
          }
        ],
        rescuephone: [
          {
            validator: (rule, value, callback) => {
              if(!value || /^1\d{10}$/.test(value)) {
                callback();
              }else {
                callback('请输入正确的手机号码')
              }
            }, trigger: 'blur'
          }
        ],
        maintenancephone1: [
          {
            validator: (rule, value, callback) => {
              if(!value || /^1\d{10}$/.test(value)) {
                callback();
              }else {
                callback('请输入正确的手机号码')
              }
            }, trigger: 'blur'
          }
        ],
        maintenancephone2: [
          {
            validator: (rule, value, callback) => {
              if(!value || /^1\d{10}$/.test(value)) {
                callback();
              }else {
                callback('请输入正确的手机号码')
              }
            }, trigger: 'blur'
          }
        ],
        safeperson: [{required: true, message: '请输入安全管理员'}],
        safephone: [
          {required: true, message: '请输入安全管理员电话'},
          {
            validator: (rule, value, callback) => {
              if(!value || /^1\d{10}$/.test(value)) {
                callback();
              }else {
                callback('请输入正确的手机号码')
              }
            }, trigger: 'blur'
          }
        ],
        areamanagerphone: [
          {
            validator: (rule, value, callback) => {
              if(!value || /^1\d{10}$/.test(value)) {
                callback();
              }else {
                callback('请输入正确的手机号码')
              }
            }, trigger: 'blur'
          }
        ],
        depmanagerphone: [
          {
            validator: (rule, value, callback) => {
              if(!value || /^1\d{10}$/.test(value)) {
                callback();
              }else {
                callback('请输入正确的手机号码')
              }
            }, trigger: 'blur'
          }
        ],
        legalphone: [
          {
            validator: (rule, value, callback) => {
              if(!value || /^1\d{10}$/.test(value)) {
                callback();
              }else {
                callback('请输入正确的手机号码')
              }
            }, trigger: 'blur'
          }
        ],
      },
      certificatepicList: [],
      deppicList: [],
      businesspicList: [],
      licensepicList: [],
      selectCoordinateVisible: false,
      peopleModalSelectedDeptid: '',
      peoplelistModalVisible: false,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['uploadHeaders', 'uploadData']),
    parentname() {
      let result = '';
      // debugger
      if(this.formDatas.parentid) {
        let parentDept = getItemFromArrayByKey(this.userDeptTree, 'userdepid', this.formDatas.parentid);
        if(parentDept) {
          result = parentDept.userdepname;
        }
      }
      return result;
    },
    levelidListOptions() {
      let result = [];
      if(this.formDatas.parentid) {
        let parentDept = getItemFromArrayByKey(this.userDeptTree, 'userdepid', this.formDatas.parentid);
        if(parentDept) {
          result = this.levelidList.filter(item => item.dickey>parentDept.levelid)
        }
      }else if(this.levelidList.length) {
        result = this.levelidList;
      }
      return result;
    }
  },
  watch: {
    'formDatas.deptype'(val) {
      this.formDatas.levelid = '';
      this.levelidList = [];
      if(val) {
        this.getLevelidList(val);
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    if(!toTab) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
  created() {
    this.init();
  },
  methods: {
    handleChange(value) {
      if(value) {
        this.selectedItem = getItemFromArrayByKey(this.userDeptTree, 'userdepid', value);
        this.setFormData('edit');
      }else {
        this.resetFormData();
        this.selectedItem = null;
      }
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    init() {
      if(getCache('logininfo', true).usersid==1){
        this.showadd=true;
      }
      this.getDeptTypes();
      this.getDeptTree();
      this.initAreaOptions();
      this.initUserDeptOptions();
    },
    //获取所有组织机构，以供查询使用
    initUserDeptOptions() {
      let params = {
        liftpropertytype: '7',
      }
      getLiftListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.userDeptOptions = res.item;
        }
      })
    },
    getDeptTypes() {
      let params = {
        dictype: '10'
      };
      getDictByDicType(params).then(res => {
        if(res && res.returncode == '0') {
          if(this.userInfo.usertype == '0') {
            this.deptTypes = res.item;
          }else {
            this.deptTypes = res.item.filter(item => item.dickey == this.userInfo.usertype)
          }
        }
      })
    },
    getLevelidList(dictype) {
      let params = {
        dictype
      };
      getDictByDicType(params).then(res => {
        if(res && res.returncode == '0') {
          this.levelidList = res.item;
        }
      })
    },
    getDeptTree(parentid='') {
      let params = {
        parentid
      };
      this.showLoading();
      getChildTreeListByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.userDeptTree = res.item.map(item => {
            item.isLeaf = item.childsize === 0;
            item.scopedSlots = {title: 'customTreeNode'}
            return item;
          });
          this.userDepOptions=this.userDeptTree
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    onLoadData(node) {
      this.searchRangeName=node.dataRef.userdepname
      return new Promise(resolve => {
        if (node.dataRef.children) {
          resolve();
          return;
        }
        let params = {
          parentid: node.dataRef.userdepid
        };
        this.showLoading();
        getChildTreeListByParentId(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            node.dataRef.children = res.item.map(item => {
              item.isLeaf = item.childsize === 0;
              item.scopedSlots = {title: 'customTreeNode'}
              return item;
            });
            this.userDeptTree = [...this.userDeptTree];
            this.userDepOptions=this.userDeptTree[node.dataRef.deptype-1].children
            resolve();
          }
        }).catch(err => {
          this.hideLoading();
        })
      });
    },
    initAreaOptions(parentcode='') {
      let params = {
        parentcode
      };
      geteChildListByParentcode(params).then(res => {
        if(res && res.returncode == '0') {
          this.areaOptions = res.item.map(item => {
            item.isLeaf = (item.areacode.length == 6 && item.areacode.substr(-2) != '00');
            return item;
          });
        }
      })
    },
    onLoadAreaChildren(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      this.getChildArea(targetOption.areacode);
    },
    getChildArea(parentcode) {
      let targetOption = getItemFromArrayByKey(this.areaOptions, 'areacode', parentcode);
      let params = {
        parentcode
      }
      geteChildListByParentcode(params).then(res => {
        targetOption.loading = false;
        if(res && res.returncode == '0') {
          targetOption.children = res.item.map(item => {
            item.isLeaf = (item.areacode.length == 6 && item.areacode.substr(-2) != '00');
            return item;
          });
          this.areaOptions = [...this.areaOptions]
        }
      }).catch(err => {
        targetOption.loading = false;
      })
    },
    initAreaTreeOptions() {
      if(this.formDatas.areacode) {
        let params = {
          areacode: this.formDatas.areacode
        }
        getParentTreeBycode(params).then(res => {
          if(res && res.returncode == '0') {
            let areacodeTreeStr = getTreeDeepString(res.item, '', 'areacode', 'childArea');
            console.log(areacodeTreeStr)
            let deepAreaList = areacodeTreeStr.split('|');
            if(deepAreaList.length > 1) {
              this.initAreaListTree(deepAreaList, 0);
            }else {
              this.formDatas.areaList = deepAreaList;
            }
          }
        })
      }
    },
    initAreaListTree(deepAreaList, index) {
      if(index < deepAreaList.length-1) {
        let parentcode = deepAreaList[index];
        let targetOption = getItemFromArrayByKey(this.areaOptions, 'areacode', parentcode);
        let params = {
          parentcode
        }
        geteChildListByParentcode(params).then(res => {
          // targetOption.loading = false;
          if(res && res.returncode == '0') {
            targetOption.children = res.item.map(item => {
              item.isLeaf = (item.areacode.length == 6 && item.areacode.substr(-2) != '00');
              return item;
            });
            // this.areaOptions = [...this.areaOptions]
            this.initAreaListTree(deepAreaList, index+1)
          }
        }).catch(err => {
          // targetOption.loading = false;
        })
      }else {
        this.formDatas.areaList = deepAreaList;
      }
    },
    addNewDept() {
      let logininfo = getCache('logininfo', true);
      if(logininfo.usersid==1) {
        this.selectedItem = null;
        this.setFormData('addNew');
      }else{
        this.$message.error("您没有权限执行该操作！")
      }

    },
    showPeopleList(node) {
      this.peopleModalSelectedDeptid = node.dataRef.userdepid;
      this.peoplelistModalVisible = true;
    },
    addChildQuick(node) {
      this.selectedItem = node.dataRef;
      this.addChild();
    },
    searchChildQuick(node) {
      this.onLoadData(node)
      this.selectedItem = node.dataRef;
    },
    deleteQuick(node) {
      if(getCache('logininfo', true).usersid!=1&&(node.dataRef.levelid==1||node.dataRef.levelid==19||node.dataRef.levelid==29||node.dataRef.levelid==39||node.dataRef.levelid==49||node.dataRef.levelid==59)){
        this.$message.error("您没有权限执行该操作！")
      }else{
        this.selectedItem = node.dataRef;
        this.deleteConfirm();
      }
    },
    addChild() {
      if(this.selectedItem) {
        this.setFormData('add');
      }else {
        this.$message.info('请选择一条机构');
      }
    },
    selectDept(item) {
      let userdepid = item[0];
      if(userdepid) {
        this.selectedItem = getItemFromArrayByKey(this.userDeptTree, 'userdepid', userdepid);
        this.setFormData('edit');
      }else {
        this.resetFormData();
        this.selectedItem = null;
      }
    },
    setFormData(type) {
      this.formType = type;
      this.resetFormData();
      if('edit' == this.formType) {
        if(this.selectedItem) {
          this.formDatas.userdepid = this.selectedItem.userdepid;
          this.formDatas.parentid = this.selectedItem.parentid;
          this.formDatas.userdepname = this.selectedItem.userdepname;
          this.formDatas.deptype = this.selectedItem.deptype;
          this.$nextTick(() => {
            this.formDatas.levelid = this.selectedItem.levelid;
          })
          this.formDatas.abbreviation = this.selectedItem.abbreviation;
          this.formDatas.sequenceno = this.selectedItem.sequenceno;
          this.formDatas.areacode = this.selectedItem.areacode;
          this.initAreaTreeOptions();
          // if(this.formDatas.areacode) {
          //   if(/^\d{2}0000$/.test(this.formDatas.areacode)) {
          //     this.formDatas.areaList = [this.formDatas.areacode];
          //   }else if(/^\d{4}00$/.test(this.formDatas.areacode)) {
          //     this.formDatas.areaList = [this.formDatas.areacode.substr(0,2)+'0000', this.formDatas.areacode];
          //   }else {
          //     this.formDatas.areaList = [this.formDatas.areacode.substr(0,2)+'0000', this.formDatas.areacode.substr(0,4)+'00', this.formDatas.areacode];
          //   }
          // }else {
          //   this.formDatas.areaList = [];
          // }
          this.formDatas.address = this.selectedItem.address;
          this.formDatas.telphone = this.selectedItem.telphone;
          this.formDatas.mailcode = this.selectedItem.mailcode;
          this.formDatas.rescuepreson = this.selectedItem.rescuepreson;
          this.formDatas.rescuephone = this.selectedItem.rescuephone;
          this.formDatas.maintenanceperson1 = this.selectedItem.maintenanceperson1;
          this.formDatas.maintenancephone1 = this.selectedItem.maintenancephone1;
          this.formDatas.maintenanceperson2 = this.selectedItem.maintenanceperson2;
          this.formDatas.maintenancephone2 = this.selectedItem.maintenancephone2;
          this.formDatas.safeperson = this.selectedItem.safeperson;
          this.formDatas.safephone = this.selectedItem.safephone;
          this.formDatas.areamanager = this.selectedItem.areamanager;
          this.formDatas.areamanagerphone = this.selectedItem.areamanagerphone;
          this.formDatas.depmanager = this.selectedItem.depmanager;
          this.formDatas.depmanagerphone = this.selectedItem.depmanagerphone;
          this.formDatas.legalperson = this.selectedItem.legalperson;
          this.formDatas.legalphone = this.selectedItem.legalphone;
          this.formDatas.legalid = this.selectedItem.legalid;
          // console.log(this.selectedItem)
          this.formDatas.coordinatex = this.selectedItem.coordinatex;
          this.formDatas.coordinatey = this.selectedItem.coordinatey;
          this.formDatas.certificatenum = this.selectedItem.certificatenum;
          this.formDatas.depnum = this.selectedItem.depnum;
          this.formDatas.businessnum = this.selectedItem.businessnum;
          this.formDatas.licensenum = this.selectedItem.licensenum;
          this.formDatas.certificatepic = this.selectedItem.certificatepic;
          if(this.formDatas.certificatepic) {
            let nameIndex = this.formDatas.certificatepic.lastIndexOf('/');
            if(nameIndex < 0) {
              nameIndex = this.formDatas.certificatepic.lastIndexOf('\\');
            }
            let fileName = this.formDatas.certificatepic.substr(nameIndex+1);
            this.certificatepicList = [{
              uid: 'certificatepicuid',
              name: fileName,
              status: 'done',
              url: this.formDatas.certificatepic,
            }];
          }
          this.formDatas.deppic = this.selectedItem.deppic;
          if(this.formDatas.deppic) {
            let nameIndex = this.formDatas.deppic.lastIndexOf('/');
            if(nameIndex < 0) {
              nameIndex = this.formDatas.deppic.lastIndexOf('\\');
            }
            let fileName = this.formDatas.deppic.substr(nameIndex+1);
            this.deppicList = [{
              uid: 'deppicuid',
              name: fileName,
              status: 'done',
              url: this.formDatas.deppic,
            }];
          }
          this.formDatas.businesspic = this.selectedItem.businesspic;
          if(this.formDatas.businesspic) {
            let nameIndex = this.formDatas.businesspic.lastIndexOf('/');
            if(nameIndex < 0) {
              nameIndex = this.formDatas.businesspic.lastIndexOf('\\');
            }
            let fileName = this.formDatas.businesspic.substr(nameIndex+1);
            this.businesspicList = [{
              uid: 'businesspicuid',
              name: fileName,
              status: 'done',
              url: this.formDatas.businesspic,
            }];
          }
          this.formDatas.licensepic = this.selectedItem.licensepic;
          if(this.formDatas.licensepic) {
            let nameIndex = this.formDatas.licensepic.lastIndexOf('/');
            if(nameIndex < 0) {
              nameIndex = this.formDatas.licensepic.lastIndexOf('\\');
            }
            let fileName = this.formDatas.licensepic.substr(nameIndex+1);
            this.licensepicList = [{
              uid: 'licensepicuid',
              name: fileName,
              status: 'done',
              url: this.formDatas.licensepic,
            }];
          }
          this.formDatas.liftpropertyid = this.selectedItem.liftpropertyid;
          this.formDatas.userdepdesc = this.selectedItem.userdepdesc;
        }else {
          this.resetFormData();
        }
      }else {
        this.selectedKeys = [];
        // debugger
        if(this.selectedItem) {
          this.formDatas.parentid = this.selectedItem.userdepid;
          this.formDatas.deptype = this.selectedItem.deptype;
        }else {
          // 顶级机构
          this.formDatas.parentid = '';
        }
      }
    },
    resetFormData() {
      this.$refs.userDeptForm.resetFields();
      // this.formDatas.userdepid = '';
      for(let key in this.formDatas) {
        if(key == 'sequenceno') {
          this.formDatas[key] = 1;
        }else if(key == 'areaList') {
          this.formDatas[key] = [];
        }else {
          this.formDatas[key] = '';
        }
      }
      // console.log(this.formDatas)
      this.formDatas.areaList = [];
      this.certificatepicList = [];
      this.deppicList = [];
      this.businesspicList = [];
      this.licensepicList = [];
    },
    save() {
      if(getCache('logininfo', true).usersid!=1&&(this.formDatas.levelid==1||this.formDatas.levelid==19||this.formDatas.levelid==29||this.formDatas.levelid==39||this.formDatas.levelid==49||this.formDatas.levelid==59)) {
        this.$message.error("您没有权限执行该操作！")
      }else {
        this.showLoading();
        if (this.formDatas.areaList.length) {
          this.formDatas.areacode = this.formDatas.areaList[this.formDatas.areaList.length - 1];
        } else {
          this.formDatas.areacode = '';
        }
        // console.log(this.formDatas);
        // return;
        let params = {
          ...this.formDatas
        };
        if (this.formType == 'edit') {
          modifyUserdep(params).then(res => {
            this.hideLoading();
            if (res && res.returncode == '0') {
              this.$message.success('操作成功');
              let dept = getItemFromArrayByKey(this.userDeptTree, 'userdepid', this.formDatas.userdepid);
              for (let key in dept) {
                if (this.formDatas[key]) {
                  dept[key] = this.formDatas[key]
                }
              }
            } else {
              this.$message.error(res.errormsg || '操作失败');
            }
          })
        } else {
          addUserdep(params).then(res => {
            this.hideLoading();
            if (res && res.returncode == '0') {
              this.$message.success('操作成功');
              let newNode = this.getDeptNode(res.userdepid, {isLeaf: true, scopedSlots: {title: 'customTreeNode'}});
              if (this.formDatas.parentid) {
                let parentDept = getItemFromArrayByKey(this.userDeptTree, 'userdepid', this.formDatas.parentid);
                parentDept.isLeaf = false;
                if (!parentDept.children || !parentDept.children.length) {
                  parentDept.children = [];
                }
                parentDept.children.push(newNode)
                this.userDeptTree = [...this.userDeptTree]
              } else {
                this.userDeptTree.push(newNode)
              }
              this.selectedItem = newNode
              this.setFormData('edit');
            } else {
              this.$message.error(res.errormsg || '操作失败');
            }
          })
        }
      }
    },
    getDeptNode(userdepid, obj) {
      return {
        ...this.formDatas,
        userdepid,
        ...obj
      }
    },
    deleteConfirm() {
      if(getCache('logininfo', true).usersid!=1&&(this.formDatas.levelid==1||this.formDatas.levelid==19||this.formDatas.levelid==29||this.formDatas.levelid==39||this.formDatas.levelid==49||this.formDatas.levelid==59)){
        this.$message.error("您没有权限执行此操作！")
      }else{
        this.$confirm({
          // title: '',
          content: '确定要删除该数据?',
          okText: '确定',
          cancelText: '取消',
          onOk:() => {
            this.delete();
          },
          onCancel() {},
        });
      }
    },
    delete() {
      this.showLoading();
      let params = {
        userdepid: this.selectedItem.userdepid
      }
      deleteUserdep(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.$message.success('操作成功');
          if(this.selectedItem.parentid) {
            let parentDept = getItemFromArrayByKey(this.userDeptTree, 'userdepid', this.selectedItem.parentid);
            parentDept.children = parentDept.children.filter(item => item.userdepid!=this.selectedItem.userdepid);
            this.userDeptTree = [...this.userDeptTree]
          }else {
            this.userDeptTree = this.userDeptTree.filter(item => item.userdepid!=this.selectedItem.userdepid);
          }
          this.resetFormData();
          this.selectedItem = null;
          this.selectedKeys = [];
        }else {
          this.$message.error(res.errormsg||'操作失败');
        }
      })
    },
    certificatepicUploaded(info) {
      let fileList = [...info.fileList];

      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);

      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.urlPath;
          this.formDatas.certificatepic = file.response.urlPath;
        }
        return file;
      });

      this.certificatepicList = fileList;
    },
    deppicUploaded(info) {
      let fileList = [...info.fileList];

      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);

      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.urlPath;
          this.formDatas.deppic = file.response.urlPath;
        }
        return file;
      });

      this.deppicList = fileList;
    },
    businesspicUploaded(info) {
      let fileList = [...info.fileList];

      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);

      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.urlPath;
          this.formDatas.businesspic = file.response.urlPath;
        }
        return file;
      });

      this.businesspicList = fileList;
    },
    licensepicUploaded(info) {
      let fileList = [...info.fileList];

      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);

      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.urlPath;
          this.formDatas.licensepic = file.response.urlPath;
        }
        return file;
      });

      this.licensepicList = fileList;
    },
    getCoordinate(coordinate) {
      this.formDatas.coordinatex = coordinate[0];
      this.formDatas.coordinatey = coordinate[1];
    },
    beforeUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isJPG && !isPNG) {
        this.$message.error('上传的文件类型只能是.jpg或者.png图片类型!');
        return false
      }
      if (!isLt5M) {
        this.$message.error('上传文件大小不能超过 5MB!');
        return false
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.dept-container {
  display: flex;
}
.select-drop-down-platform {
  width: 240px;
}
</style>